/* Navbar.css */

.custom-navbar {
  background-color: transparent;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2; /* Higher z-index for the navbar */
  width:100%;
}

.custom-navbar a {
  color: white;
  text-decoration: none; /* Remove underline */
   font-size: '20px';
   font-weight: light;
}



.custom-navbar.scrolled a {
  color: black;
}

.custom-navbar .brand-link {
  margin-right: 600px; /* Adjust the margin to create space between h2 and other items */
}

.brand-link {
  text-decoration: none;
}
