.testimonial-card {
    transition: box-shadow 0.3s;
    display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  
  }
  
  .testimonial-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .user-icon {
    color: rgb(9, 67, 76);
    font-size: 2rem;
    margin-bottom: 1rem;
  }