/* Home.css */

.custom-video-container {
  position: relative;
  z-index: 1; /* Lower z-index for the video container */
}

.custom-video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.container {
  position: relative;
  z-index: 1;
}

.video-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.video-overlay h1 {
  font-size: 5rem;
  margin-bottom: 40px;
}

.video-overlay p {
  font-size: 1.5rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .video-overlay h1 {
    font-size: 3rem;
  }

  .video-overlay p {
    font-size: 1.2rem;
  }
}
.top-rated-button {
  background-color: #3498db; 
  color: #ffffff; /* Text color */
  padding: 10px 15px; 
  border: none;
  cursor: not-allowed;
  width:120px;
  margin-bottom: 20px;
  border-radius: 2px;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px; /* Add padding */
  transition: transform 0.3s ease-in-out; /* Add transition effect */
  height: 300px;
}
.card:hover {
  transform: scale(1.05); /* Add scaling effect on hover */
}

.icon1 {
  font-size: 4.5rem;
  color: rgb(214, 53, 53);
  margin-bottom: 40px;
}