
/* Home.css */

.custom-image-container {
    position: relative;
    z-index: 1; /* Lower z-index for the video container */
  }
  
  .custom-image {
    width: 100%;
    height: 70vh;
    object-fit: cover;
  }
  
  .container {
    position: relative;
    z-index: 1;
  }
  
  .image-overlay {
    position: absolute;
    bottom: 30px;
    left: 30px;
    text-align: left;
    color: white;
  }
  
.contact-content{
    display:flex;
    justify-content: center;
    margin-bottom: 55px;

}
  .icon2 {
    margin-bottom: 15px;
    color: #07353c
  }
  a{
    text-decoration: none;
  }
  .contact-details .email,
.contact-details .phone {
  display: flex;
  align-items: center;
}
.thank-you{
    width:100%;
    margin-right:2px;
}
